import {Link} from 'react-router-dom';
import Sections from "./Sections";

function Home() {

  return (
    <div style={{margin: "100px", padding: "20px;", backgroundColor: "ash", fontFamily: "serif", fontSize: "20pt"}}>
        <h1>School Form 10</h1>
        <ol>
          <li>Students from <Link to="/one" >SY 2021-2022</Link></li>
          <li>Students from <Link to="/second" >SY 2022-2023</Link></li>
          <li>Students from <Link to="/third" >SY 2023-2024</Link></li>
          <li>Students from <Link to="/g11" >SY 2023-2024 (G11)</Link></li>
        </ol>
    </div>
   
  );
}

export default Home;
