import {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import  iris from './iris.png';

function Sections({sec2, setSec}){
    const url = "https://pcaatportal.com/db2.php";
    const [arr, setArr] = useState([]);
  
    const navigate = useNavigate();

    

    useEffect(()=>{
       
        axios.get(url).then((response) =>{
            setArr(response.data)
          

        });
    },[])

    const section = (e) =>{
        sec2 = e.currentTarget.title;
        setSec(e.currentTarget.title)
        navigate('/'+sec2 + '2' )
    }

    return(
        <div style={{marginLeft:"50px", padding: "20px "}}>
        {/* <img src={iris} style={{position: "absolute", width: "100px"}} /> */}
        <h1>SF10 BATCH 2022-2023</h1>
        <button onClick={()=>{navigate('/')}} style={{width: "150px", textAlign: "left", backgroundColor:"yellow"}}>MAIN MENU</button>
        <h2>Choose Section:</h2>
        {arr.map((item)=>{
            return(
                <>
                <li><button title={item.table_name} onClick={section} style={{width: "150px", textAlign: "left", backgroundColor:"lightblue"}}>{item.table_name.toUpperCase()}</button></li>
                </>
            )
            
        })}
        </div>
    )
}

export default Sections;